import { Link } from "gatsby-plugin-react-intl";
import request from "graphql-request";
import React, { useEffect, useState } from "react";
import "../assets/css/collab.scss";
import Question from "../components/accordion";
import Head from "../components/head";
import Layout from "../components/layout";
import Loading from "../components/loading";
import ShowProvider from "../components/showProvider";
import Slider from "../components/slider";
import EmailIcon from "../images/email-icon.svg";

function CollabPage({ pageContext }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [buttons, setButtons] = useState({});
  const [sliderData, setSliderData] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      const res = await request(
        process.env.GATSBY_GRAPHQL_URL,
        `{
          collab(locale: "${pageContext.intl.language}") {
            page_title
            title
            img {
              url
            }
            subtitle
            title2
            description
            title_faq
            img_faq {
              url
            }
            collab_single {
              step
              title
              description
            }
            faq {
              question
              answear
            }
          }
          button(locale: "${pageContext.intl.language}"){
            contactus
          }
        }`
      );
      return res;
    };
    fetch().then((items) => {
      setData(items.collab);
      setButtons(items.button);
      setLoading(false);
    });
  }, []);

  const strapiHost = process.env.GATSBY_API_URL;

  return (
    <>
      <Layout contactUs={false} fullHeight={false} initial={false} />
      {loading ? (
        <Loading />
      ) : (
        <ShowProvider>
          <Layout
            contactUs={false}
            fullHeight={false}
            initial={false}
            nav={false}
          >
            <div>
              <div className="collab__section1">
                <div className="collab__left">
                  <img
                    src={`${strapiHost}${data.img.url}`}
                    className="collab__img"
                  />
                </div>
                <div className="collab__right">
                  <h1 className="collab__right-title">{data.title}</h1>
                  <h2 className="collab__right-subtitle">{data.subtitle}</h2>
                  <p className="collab__right-description">
                    {data.description}
                  </p>
                  <Link to="/contact">
                    <button className="btn btn-primary">
                      <img src={EmailIcon} />
                      {buttons.contactus}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </Layout>
          <div className="collab__slider">
            <Slider items={sliderData} />
          </div>
          <Layout nav={false} initial={false} contactUs={false}>
            <div>
              <h2 className="collab__title2">{data.title2}</h2>
              <div className="collab__wrapper">
                {data.collab_single.map(({ description, step, title }, key) => (
                  <div className="collab__single" key={key}>
                    <h2 className="collab__single-title">{title}</h2>
                    <p className="collab__single-desc1">{description}</p>
                    <p className="collab__single-step">{step}</p>
                  </div>
                ))}
              </div>
              <h2 className="collab__faq-title">{data.title_faq}</h2>

              <div className="collab__faq">
                <div className="collab__faq-left">
                  <img
                    src={`${strapiHost}${data.img_faq.url}`}
                    className="collab__faq-img"
                  />
                </div>
                <div className="collab__faq-right">
                  {data.faq.map(({ answear, question }, index) => (
                    <Question
                      question={question}
                      answear={answear}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Layout>
        </ShowProvider>
      )}

      <Layout nav={false} initial={false} contactUs={false} />
    </>
  );
}

export default CollabPage;
