import React, { useState } from "react";

function Question({ question, answear }) {
  const [isActive, setActive] = useState("false");
  const Toggle = () => {
    setActive(!isActive);
  };
  return (
    <div className="collab__faq-single">
      <div className="collab__faq-question-wrapper">
        <p
          className={`collab__faq-question collab__faq-question${
            isActive ? "--show" : "--hide"
          }`}
          onClick={Toggle}
        >
          {question}
        </p>
      </div>
      <p
        className={
          isActive ? "collab__faq-answear" : "collab__faq-answear-active"
        }
      >
        {answear}
      </p>
    </div>
  );
}

export default Question;
